import React from 'react';
import { Formik } from 'formik';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../actions/actions';

import { addEntry } from './../firebase';

import { EMAIL_RECIPIENT, BCC_RECIPIENT } from '../constants';

import * as firebase from 'firebase/app';

// Fetching the SMTP key and password from environment variables
const smtpKey = process.env.REACT_APP_SMTP_KEY;
const smtpPass = process.env.REACT_APP_SMTP_PASS;

// todo: touched and button blur

class Form extends React.Component {

  onFormSubmit = values => {
    const items = this.props.items;

    // we need the cart as props, then just a straight up flex submit
    const formValues = Object.keys(values).map((key, index) => {
      return `${key}: ${values[key]}`;
    });


    const details = {
      method: 'smtp2go',
      key: smtpKey,
      domain: 'mg.materialised.com.au',
      user: 'materialised',
      pass: smtpPass,
      host: 'mail.smtp2go.com',
      port: 2525,
      from: 'noreply@materialised.com',
      to: EMAIL_RECIPIENT,
      cc: values.email,
      bcc: BCC_RECIPIENT,
      subject: `New image library enquiry`,
      name: values.name,
      content: `<p>Enquiry details:</p>
			<ul>
				${formValues.map(val => `<li>${val}</li>`).join('')}
			</ul>
			<p>Items:</p>
			<ul>
				${items
          .map(
            val => `<li>
					<p>Print type: ${val.selectedPrintType}</p>
					<p>Quantity: ${val.quantity}</p>					
					<p>Print size: ${val.selectedSize}</p>
					<p>Frame type: ${val.selectedFrameOption}</p>
          <p>Frame orientation: ${val.selectedFrameOrientation}</p>
          <p><a href="${val.link}">Print URL: ${val.link}</a></p>
          <p>Print SKU: ${val.sku}</p>
          ${val.addWhitePaperBoard && `<p>Add white paper board: true</p>`}
          ${val.addAntiReflection && `<p>Add anti reflection: true</p>`}
          ${val.addSecurityFixtures && `<p>Add security fixtures: true</p>`}
					</li>`,
          )
          .join('')}
			</ul>
			`,
    };

    // save to a db so we can review later
    addEntry({ ...details, items, createdAt: firebase.firestore.FieldValue.serverTimestamp() });

    const formBody = Object.keys(details)
      .map(
        key => encodeURIComponent(key) + '=' + encodeURIComponent(details[key]),
      )
      .join('&');

    return new Promise((resolve, reject) => {
      fetch('https://node-mailgun-mate.adaptable.app/post', {
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formBody,
      })
        .then(res => res.text())
        .then(text => {
          resolve(text);
        })
        .catch(() => resolve('Can’t access response. Blocked by browser?'));
    });
  };

  validate = values => {
    let errors = {};
    if (
      !values.email ||
      !values.name ||
      !values.company_name ||
      !values.abn ||
      !values.phone
    ) {
      errors.message = 'Please fill out all of the required fields';
    }
    console.log(errors);
    return errors;
  };

  render() {
    return (
      <React.Fragment>
        <h4 className="widget-title">Please enter your details</h4>

        <Formik
          initialValues={{
            name: '',
            company_name: '',
            abn: '',
            email: '',
            phone: '',
            project_name: '',
            address: '',
            additional_comments: '',
          }}
          validate={values => this.validate(values)}
          onSubmit={(values, { setSubmitting }) => {

            this.props.actions.hideToast();

            this.onFormSubmit(values).then(result => {
              setSubmitting(false);
              this.props.actions.showToast(
                'Thank you, your enquiry has been received 🎉',
              );

              setTimeout(() => {
                this.props.actions.hideToast();
              }, 10000);
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form
              onSubmit={handleSubmit}
              className={`grid-2 ${isSubmitting ? 'is-loading' : ''}`}
            >
              <div className="span-2 error-message">{errors.message}</div>

              <div>
                <label>Your name *</label>
                <input
                  name="name"
                  data-validation="required"
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label>Company name *</label>
                <input
                  name="company_name"
                  data-validation="required"
                  type="text"
                  value={values.company_name}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label>ABN *</label>
                <input
                  name="abn"
                  data-validation="required"
                  type="text"
                  value={values.abn}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label>Email *</label>
                <input
                  name="email"
                  data-validation="email"
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label>Phone *</label>
                <input
                  name="phone"
                  data-validation="custom"
                  data-validation-regexp="[0-9 ]{8,15}$"
                  data-validation-error-msg="Please enter a valid phone number"
                  type="tel"
                  value={values.phone}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label>Delivery Address</label>
                <input
                  name="address"
                  type="text"
                  value={values.address}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label>Project name</label>
                <input
                  name="project_name"
                  type="text"
                  value={values.project_name}
                  onChange={handleChange}
                />
              </div>

              <div className="span-2">
                <label>Additional comments</label>
                <textarea
                  name="additional_comments"
                  value={values.additional_comments}
                  onChange={handleChange}
                ></textarea>
              </div>

              <button
                className="pointer btn span-2"
                type="submit"
                disabled={isSubmitting}
              >
                {!isSubmitting ? 'Request a quote' : 'Requesting'}
              </button>
            </form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    items: state.reducer.items,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
